
import { defineComponent, ref, toRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { Address } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "edit-account-billing-modal",
  components: {},
  props: ["account"],

  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editAccountBillingRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    let targetData = toRef(props, "account");
    if (targetData.value.billingAddress === undefined) {
      targetData.value.billingAddress = new Address();
    }
    const rules = ref({});

    const showConfirmation = () => {
      hideModal(editAccountBillingRef.value);
      Swal.fire({
        text: "Billing details saved!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };

    const finishConfirmation = () => {
      hideModal(editAccountBillingRef.value);
      loading.value = false;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          let params = { ...targetData.value };

          if (targetData.value.id !== "") {
            ApiService.update("api/v1/accounts", props.account.id, params)
              .then(showConfirmation)
              .finally(finishConfirmation);
          }
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      editAccountBillingRef,
    };
  },
});
