
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditAccountBillingModal from "@/components/modals/forms/EditAccountBillingModal.vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import AddPaymentMethodModal from "@/components/modals/forms/AddPaymentMethodModal.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-billing",
  props: ["redirectUrl", "limit"],
  components: { AddPaymentMethodModal, EditAccountBillingModal },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const addCardVisible = ref<boolean>(false);

    const deleteCard = async (method) => {
      if (loading.value == false) {
        try {
          loading.value = true;
          await store.dispatch(Actions.REMOVE_PAYMENT_METHOD, method);
        } catch (e) {
          console.log(e);
        } finally {
          loading.value = false;
        }
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Billing", ["Account"]);
      store.dispatch(Actions.REFRESH_PAYMENT_METHODS);
    });
    return {
      deleteCard,
      loading,
      addCardVisible,
    };
  },
});
