
import { defineComponent, onActivated, onMounted, ref, watch } from "vue";

import ApiService from "@/core/services/ApiService";
// import store from "@/store";
// import { Actions } from "@/store/enums/StoreEnums";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal, showModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "add-payment-method-modal",
  components: {
    StripeElements,
    StripeElement,
  },
  props: ["account", "user", "redirectUrl"],

  //setup(props, context) {
  setup(props) {
    const stripeLoaded = ref(false);
    const loading = ref(false);
    const card = ref();
    const elms = ref();
    const store = useStore();
    const addPaymentMethodRef = ref<null | HTMLElement>(null);
    const stripeKey = ref("pk_test_MBxnDwh20V2IvkJkLSnSUgkp"); // test key
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref({
      clientSecret: "",
    });
    const cardOptions = ref({});

    const addCard = async () => {
      loading.value = true;
      const { error } = await elms.value.instance.confirmSetup({
        elements: elms.value.elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });
      loading.value = false;
      if (error) {
        console.log(error);
      } else {
        hideModal(addPaymentMethodRef.value);
        await store.dispatch(Actions.REFRESH_PAYMENT_METHODS);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };

    const loadIntent = async () => {
      let acctId = store.state.AuthModule.account.id;
      if (acctId !== undefined) {
        let response = await ApiService.get(
          "api/v1/accounts/" + acctId + "/payment_methods/create"
        );
        elementsOptions.value.clientSecret = response.data.clientSecret;
        //await loadStripe(stripeKey.value);
        stripeLoaded.value = true;
      }
    };

    onMounted(() => {
      loadIntent();
      showModal(addPaymentMethodRef.value);
    });
    onActivated(() => {
      loadIntent();
    });

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        loadIntent();
      }
    );

    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      addCard,
      elms,
      loading,
      store,
      addPaymentMethodRef,
      loadIntent,
    };
  },
});
